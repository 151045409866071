import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ApiHelper from "../../../../helpers/ApiHelper";

class Success extends Component {
    componentWillMount() {
        let state = {
            interests: [],
        };

        if (this.props.contact && this.props.contact.interests && this.props.contact.interests.length > 0) {
            for (let i in this.props.contact.interests) {
                const interest = this.props.contact.interests[i];
                interest.checked = false;
                state.interests.push(interest);
            }
        }

        this.setState(state);
    }

    componentDidMount() {}

    save() {
        this.setState({
            isSaving: true,
        });

        let interests = [];
        for (let i in this.state.interests) {
            if (this.state.interests[i] && this.state.interests[i].checked) {
                interests.push(this.state.interests[i].id);
            }
        }

        ApiHelper.request("/campaign/contact/success", {
            contact_id: this.props.contact.id,
            campaign_id: this.props.campaign.id,
            interests: interests,
        }).then(() => {
            this.props.alert("Erfolg(e) erfolgreich gespeichert.");
            this.props.closeModal(true);
        });
    }

    render() {
        let { contact } = this.props;
        let hasChecked = false;

        return (
            <React.Fragment>
                <h1>Erfolg</h1>
                <div className="form-group">
                    <label>Sparten:</label>
                    <table className="table" style={{ margin: "0px" }}>
                        <tbody>
                            {contact && contact.interests && contact.interests.length > 0
                                ? contact.interests.map((interest) => {
                                      if (interest.checked) {
                                          hasChecked = true;
                                      }

                                      let successData = null;
                                      if (this.props.successes && this.props.successes.length > 0) {
                                          for (let i in this.props.successes) {
                                              if (this.props.successes[i].interest_id === interest.id) {
                                                  successData = this.props.successes[i];
                                              }
                                          }
                                      }

                                      return (
                                          <tr key={"contact_interest_" + interest.id}>
                                              <td>
                                                  {interest.sparte_name ? interest.sparte_name : "Unbekannte Sparte"}

                                                  <div>
                                                      {interest.is_current ? (
                                                          <span className="badge badge-success">Hat Interesse</span>
                                                      ) : (
                                                          <span className="badge badge-danger">Kein Interesse</span>
                                                      )}
                                                      <div>
                                                          <small>{interest.serialnumber}</small>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td>
                                                  {successData ? (
                                                      <div style={{ opacity: 0.65 }}>
                                                          Es wurde bereits ein Erfolg für diesen Anschluss in dieser
                                                          Kampagne aufgenommen
                                                      </div>
                                                  ) : (
                                                      <label>
                                                          <input
                                                              type="checkbox"
                                                              style={{ marginRight: "3px", marginBottom: "3px" }}
                                                              onChange={(e) => {
                                                                  let interests = this.state.interests;
                                                                  for (let i in interests) {
                                                                      if (
                                                                          interests[i] &&
                                                                          interests[i].id === interest.id
                                                                      ) {
                                                                          interests[i].checked = e.target.checked;
                                                                      }
                                                                  }

                                                                  this.setState({
                                                                      interests: interests,
                                                                  });
                                                              }}
                                                              checked={interest.checked}
                                                          />
                                                          Vertrag für den Anschluss <b>{interest.serialnumber}</b>{" "}
                                                          {interest.sparte_name
                                                              ? "(" + interest.sparte_name + ") "
                                                              : ""}
                                                          geschrieben
                                                      </label>
                                                  )}
                                              </td>
                                          </tr>
                                      );
                                  })
                                : null}
                        </tbody>
                    </table>
                </div>

                <button
                    className="btn btn-success btn-block btn-lg"
                    style={{ textTransform: "none", height: "100%" }}
                    onClick={this.save.bind(this)}
                    disabled={hasChecked === false}
                >
                    <i className="far fa-save" />
                    <br />
                    Erfolg speichern
                </button>
            </React.Fragment>
        );
    }
}

export default withRouter(Success);
