import { combineReducers, compose, createStore } from "redux";

import reducers from "./reducers";

const rootReducer = combineReducers({
    ...reducers,
});

let rootEnhancer = compose();
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    rootEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: "meeva STWBO-Vertriebsapp",
    })(rootEnhancer);
}

export default function configureStore(initialState = {}) {
    const store = createStore(rootReducer, initialState, rootEnhancer);

    return store;
}
