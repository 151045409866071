import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import ApiHelper from "../helpers/ApiHelper";
import { setAuth, setRelogin } from "../redux/actions";

class Login extends Component {
    state = {
        isLoading: false,
        hasError: null,
        email: "",
        password: "",
    };

    constructor() {
        super();

        this.valueChange = (e) => {
            this.setState({
                [e.target.name]: e.target.value,
            });
        };
    }

    componentDidMount() {
        this.props.setRelogin(false);
    }

    submit(e) {
        e.preventDefault();

        this.setState({
            hasError: null,
            isLoading: true,
        });

        ApiHelper.request("/login", {
            email: this.state.email,
            password: this.state.password,
        })
            .then((response) => {
                this.props.setApi(response.api_token, response.user);
                this.props.history.push("/");
            })
            .catch(() => {
                this.setState({
                    hasError: (
                        <div>
                            <b>Fehler beim Einloggen</b>
                            <br />
                            Kombination aus E-Mail und Passwort ist nicht gültig.
                        </div>
                    ),
                    isLoading: false,
                    password: "",
                });
            });
    }

    render() {
        return (
            <div>
                <h1 className="text-center">Login Formular</h1>

                {this.state.hasError ? <div className="alert alert-danger">{this.state.hasError}</div> : null}
                <form onSubmit={this.submit.bind(this)}>
                    <div className="form-group">
                        <label>E-Mail</label>
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={this.state.email}
                            onChange={this.valueChange}
                            disabled={this.state.isLoading}
                        />
                    </div>
                    <div className="form-group">
                        <label>Passwort</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={this.state.password}
                            onChange={this.valueChange}
                            disabled={this.state.isLoading}
                        />
                    </div>
                    <input
                        type="submit"
                        className="btn btn-blue-grey btn-block"
                        style={{ textTransform: "none" }}
                        disabled={this.state.isLoading}
                        value={this.state.isLoading ? "Wird geladen ..." : "Einloggen"}
                    />
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.app.auth,
});

const mapDispatch = (dispatch) => ({
    setApi: (token, user) => dispatch(setAuth(token, user)),
    setRelogin: (changeRelogin) => dispatch(setRelogin(changeRelogin)),
});
export default withRouter(connect(mapStateToProps, mapDispatch)(Login));
