import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { setAuth } from "../redux/actions";

class PrivateRoute extends Component {
    reloadSessionFromOffline() {
        let api_token = localStorage.getItem("api_token");
        let user = localStorage.getItem("user");

        if (api_token && api_token.trim() !== "" && user && user.trim() !== "") {
            user = JSON.parse(user);

            if (user && user.id > 0 && user.username && user.email && user.username.trim() !== "") {
                this.props.setApi(api_token, user);
                return true;
            }
        }

        return false;
    }

    render() {
        if (
            (this.props.auth && this.props.auth.token && this.props.auth.user && this.props.auth.user.id > 0) ||
            this.reloadSessionFromOffline()
        ) {
            return <Route {...this.props} />;
        }

        return <Redirect to="/login" />;
    }
}

const mapStateToProps = (state) => ({
    auth: state.app.auth,
});
const mapDispatch = (dispatch) => ({
    setApi: (token, user) => dispatch(setAuth(token, user)),
});
export default connect(mapStateToProps, mapDispatch)(PrivateRoute);
