import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import ApiHelper from "../../../helpers/ApiHelper";
import Actions from "./Actions";

import { connect } from "react-redux";
import { setBacklink, setRelogin } from "../../../redux/actions";

class ContactCampaignDetails extends React.Component {
    state = {
        isLoading: true,
        hasError: false,
        isSaving: false,
        contact: null,
        campaign: null,
        last_action: null,
        last_action_type: null,
        alertMessage: null,
        alertType: null,
        alertTime: null,
        prevContactId: null,
        nextContactId: null,
        successes: null,
    };

    debounceHelper = 0;

    componentDidMount() {
        this.props.setBacklink("/campaign/" + this.props.match.params.campaignId + "/details");

        this.load(this.props.match.params.campaignId, this.props.match.params.contactId);
    }

    load(campaignId, contactId) {
        this.setState({
            isLoading: true,
            hasError: false,
            contact: null,
            campaign: null,
            last_action: null,
            last_action_type: null,
            prevContactId: null,
            nextContactId: null,
            successes: null,
        });

        ApiHelper.request("/campaign/contact/data", {
            campaign_id: campaignId,
            contact_id: contactId,
        })
            .then((response) => {
                this.setState({
                    hasError: false,
                    isLoading: false,
                    campaign: response.data.campaign,
                    contact: response.data.contact,
                    last_action: response.data.last_action,
                    last_action_type: response.data.last_action_type,
                    prevContactId: response.data.prevContact,
                    nextContactId: response.data.nextContact,
                    successes: response.data.successes,
                });
            })
            .catch((e) => {
                this.setState({
                    hasError: true,
                    isLoading: false,
                });

                if (e.statusText === "Forbidden") {
                    this.props.setRelogin(true);
                }
            });
    }

    noteChange(e) {
        let contact = this.state.contact;
        contact.note = e.target.value;

        this.setState(
            {
                contact: contact,
            },
            () => {
                this.debounceHelper++;
                setTimeout(() => {
                    this.debounceHelper--;

                    if (this.debounceHelper === 0) {
                        this.setState({
                            isSaving: true,
                        });
                        ApiHelper.request("/contact/change-values", {
                            contact_id: contact.id,
                            change: {
                                note: this.state.contact.note,
                            },
                        }).then(() => {
                            this.setState({
                                isSaving: false,
                            });
                        });
                    }
                }, 1000);
            }
        );
    }

    alert(message, type) {
        if (type === undefined || !type) {
            type = "success";
        }

        this.setState({
            alertMessage: message,
            alertType: type,
            alertTime: moment(),
        });
    }

    changeContact(contactId) {
        this.props.history.push("/campaign/" + this.state.campaign.id + "/contact/" + contactId + "/details");
        this.load(this.state.campaign.id, contactId);
    }

    render() {
        let content;
        if (this.state.isLoading) {
            content = (
                <div style={{ marginTop: "30px", textAlign: "center" }}>
                    <i className="zmdi zmdi-spinner zmdi-hc-fw rotating" style={{ fontSize: 28 }} />
                    <br />
                    Daten werden geladen ...
                </div>
            );
        } else if (this.state.hasError) {
            content = (
                <div style={{ marginTop: "30px" }} className="alert alert-danger">
                    Es ist ein Fehler beim Laden des Kontakts aufgetreten.
                </div>
            );
        } else {
            let { contact, campaign, successes } = this.state;

            content = (
                <React.Fragment>
                    {this.state.isSaving ? (
                        <div className="float-right">
                            <i className="zmdi zmdi-spinner zmdi-hc-fw rotating" /> speichert ...
                        </div>
                    ) : null}

                    {/* Alert Hinweis von Actions */}
                    {this.state.alertMessage ? (
                        <div className={"alert alert-" + this.state.alertType}>
                            <b>{this.state.alertTime.format("HH:mm")} Uhr:</b> {this.state.alertMessage}
                        </div>
                    ) : null}

                    {/* Bereits geschehen Hinweis */}
                    {this.state.last_action ? (
                        <div className="alert alert-info">
                            {this.state.last_action_type === "meeting" ? (
                                <span>
                                    Es wurde bereits ein <b>Termin</b> vereinbart
                                </span>
                            ) : null}
                            {this.state.last_action_type === "no-interest" ? (
                                <span>Kontakt hat kein Interesse ({moment(this.state.last_action).calendar()})</span>
                            ) : null}
                            {this.state.last_action_type === "not-met" ? (
                                <span>
                                    Kontakt wurde zuletzt am {moment(this.state.last_action).format("L")} nicht
                                    angetroffen
                                </span>
                            ) : null}
                            {this.state.last_action_type === "success" ? (
                                <span>Es wurde am {moment(this.state.last_action).format("L")} ein Erfolg erfasst</span>
                            ) : null}
                        </div>
                    ) : null}

                    <div className="container-fluid row" />

                    {/* Adresse */}
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9">
                                    <h1 className="title">
                                        {contact.lastname}, {contact.firstname}{" "}
                                        {contact.label && <>({contact.label})</>}
                                    </h1>
                                    <div style={{ fontSize: "1.0rem" }}>
                                        {contact.address.street} {contact.address.housenumber}
                                        <br />
                                        {contact.address.zip} {contact.address.city}
                                        <br />
                                    </div>
                                </div>
                                <div className="col-2" style={{ marginRight: "0px" }}>
                                    <span
                                        className={`jr-menu-icon pointer`}
                                        style={{ float: "right", marginRight: "0px" }}
                                    >
                                        {this.state.prevContactId !== null ? (
                                            <span
                                                className={"zmdi zmdi-long-arrow-left zmdi-hc-2x pointer"}
                                                onClick={() => this.changeContact(this.state.prevContactId)}
                                            />
                                        ) : null}
                                        {this.state.nextContactId !== null ? (
                                            <span
                                                className={"zmdi zmdi-long-arrow-right zmdi-hc-2x pointer"}
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => this.changeContact(this.state.nextContactId)}
                                            />
                                        ) : null}
                                    </span>
                                </div>
                            </div>
                            <Actions
                                campaign={campaign}
                                contact={contact}
                                successes={successes}
                                alert={this.alert.bind(this)}
                                load={this.load.bind(this)}
                            />
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">Interessen</div>
                        <table className="table" style={{ margin: "0px" }}>
                            <tbody>
                                {contact && contact.interests && contact.interests.length > 0
                                    ? contact.interests.map((interest) => {
                                          let successData = null;
                                          if (successes && successes.length > 0) {
                                              for (var i in successes) {
                                                  if (successes[i].interest_id === interest.id) {
                                                      successData = successes[i];
                                                  }
                                              }
                                          }

                                          return (
                                              <tr
                                                  key={"contact_interest_" + interest.id}
                                                  style={{ opacity: successData ? 0.7 : 1 }}
                                              >
                                                  <td>
                                                      {interest.sparte_name
                                                          ? interest.sparte_name
                                                          : "Unbekannte Sparte"}
                                                      <div>
                                                          <small>{interest.serialnumber}</small>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      {interest.is_current ? (
                                                          <span className="badge badge-success">Hat Interesse</span>
                                                      ) : (
                                                          <span className="badge badge-danger">Kein Interesse</span>
                                                      )}

                                                      {successData ? (
                                                          <div style={{ fontSize: "11px", marginTop: "3px" }}>
                                                              Es wurde bereits ein Vertrag am{" "}
                                                              {moment(successData.date).format("L")} erstellt.
                                                          </div>
                                                      ) : null}
                                                  </td>
                                              </tr>
                                          );
                                      })
                                    : null}
                            </tbody>
                        </table>
                    </div>

                    {/* Notiz */}
                    <div className="card">
                        <div className="card-header" style={{ padding: "16px 22px" }}>
                            Notiz
                        </div>
                        <textarea
                            className="form-control"
                            style={{ border: "none", boxShadow: "none", background: "transparent", padding: "25px" }}
                            rows="4"
                            placeholder="Interne Notiz für den Kontakt erfassen ..."
                            onChange={this.noteChange.bind(this)}
                            value={this.state.contact.note ? this.state.contact.note : ""}
                        />
                    </div>
                </React.Fragment>
            );
        }

        return <React.Fragment>{content}</React.Fragment>;
    }
}

const mapStateToProps = (state) => ({
    backlink: state.app.backlink,
});
const mapDispatch = (dispatch) => ({
    setBacklink: (backlink) => dispatch(setBacklink(backlink)),
    setRelogin: (changeRelogin) => dispatch(setRelogin(changeRelogin)),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(ContactCampaignDetails));
