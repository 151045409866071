import {
    CLOSE_SEARCH,
    CLOSE_SIDEBAR,
    LOGOUT,
    OPEN_SEARCH,
    OPEN_SIDEBAR,
    SEND_SEARCH,
    SET_AUTH,
    SET_BACKLINK,
    SET_RELOGIN,
    UPDATE_SEARCH,
} from "./actions";

const initialBasicStoreState = {
    auth: null,
    sidebarOpen: false,
    backlink: null,
    searchOpen: false,
    searchInput: "",
    sendSearch: false,
    relogin: false,
};

const app = (state = initialBasicStoreState, action) => {
    switch (action.type) {
        case SET_AUTH:
            return {
                ...state,
                auth: {
                    user: action.payload.user,
                    token: action.payload.token,
                },
            };
        case OPEN_SIDEBAR:
            return {
                ...state,
                sidebarOpen: true,
            };
        case CLOSE_SIDEBAR:
            return {
                ...state,
                sidebarOpen: false,
            };
        case OPEN_SEARCH:
            return {
                ...state,
                searchOpen: true,
            };
        case CLOSE_SEARCH:
            return {
                ...state,
                searchOpen: false,
            };
        case UPDATE_SEARCH:
            return {
                ...state,
                searchInput: action.payload.searchInput,
            };
        case SEND_SEARCH:
            return {
                ...state,
                sendSearch: action.payload.sendSearch,
            };
        case SET_BACKLINK:
            return {
                ...state,
                backlink: action.payload.backlink,
            };
        case LOGOUT:
            return {
                ...state,
                auth: {
                    user: null,
                    token: null,
                },
            };
        case SET_RELOGIN:
            return {
                ...state,
                auth: null,
                relogin: action.payload.relogin,
            };
        default:
            return state;
    }
};

export default { app };
