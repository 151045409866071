class ApiHelper {
    store = null;

    setStore(store) {
        this.store = store;
    }

    async request(path, data) {
        if (!data) {
            data = {};
        }

        let state = this.store.getState();
        if (state.app.auth && state.app.auth.token && state.app.auth.user && state.app.auth.user.id) {
            data.auth = {
                token: state.app.auth.token,
            };
        }

        const rawResponse = await fetch(this.createApiUrl(path), {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: this.serialize(data),
        });

        return await rawResponse.json();
    }

    serialize(obj, prefix) {
        let str = [],
            p;
        for (p in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, p)) {
                let k = prefix ? prefix + "[" + p + "]" : p,
                    v = obj[p];
                str.push(
                    v !== null && typeof v === "object"
                        ? this.serialize(v, k)
                        : encodeURIComponent(k) + "=" + encodeURIComponent(v)
                );
            }
        }
        return str.join("&");
    }

    createApiUrl(path) {
        path = path.trim();
        if (path.substring(0, 1) !== "/") {
            path = "/" + path;
        }

        return process.env.REACT_APP_API_PATH + path;
    }
}
export default new ApiHelper();
