import React from "react";
import { Link, withRouter } from "react-router-dom";
import { List, ListEntry, Tabs } from "../../theme";
import ApiHelper from "../../helpers/ApiHelper";

import { connect } from "react-redux";
import { setBacklink, setRelogin } from "../../redux/actions";

class View extends React.Component {
    state = {
        isLoading: true,
        contactsOpen: [],
        contactsBlocked: [],
        contactsClosed: [],
        id: null,
        name: null,
        currentTab: "open",
        currentPage: 0,
        numPages: 10,
        contactsPerPage: 15,
    };

    componentDidMount() {
        this.props.setBacklink("/");
        this.load();
    }

    load() {
        this.setState({
            isLoading: true,
            contactsOpen: [],
            contactsBlocked: [],
            contactsClosed: [],
        });

        ApiHelper.request("/campaigns/details", {
            id: this.props.match.params.campaignId,
        })
            .then((response) => {
                this.setState(
                    {
                        isLoading: false,
                        contactsOpen: response.data.contacts_open,
                        contactsBlocked: response.data.contacts_blocked,
                        contactsClosed: response.data.contacts_closed,
                        id: response.data.id,
                        name: response.data.name,
                    },
                    () => {
                        this.updateMaxPages();
                    }
                );
            })
            .catch((e) => {
                console.error(e);
                this.setState({ isLoading: false, contactsOpen: [], contactsClosed: [] });

                if (e.statusText === "Forbidden") {
                    this.props.setRelogin(true);
                }
            });
    }

    renderContactListElement(contact) {
        let hint;
        if (contact.last_action && contact.last_action_type) {
            if (contact.last_action_type === "meeting") {
                hint = <div style={{ fontSize: "10px", color: "green" }}>Termin</div>;
            } else if (contact.last_action_type === "no-interest") {
                hint = <div style={{ fontSize: "10px", color: "red" }}>Kein Interesse</div>;
            }
        }

        return (
            <ListEntry
                key={"contact_" + contact.id}
                onClick={() =>
                    this.props.history.push(
                        "/campaign/" + this.props.match.params.campaignId + "/contact/" + contact.id + "/details"
                    )
                }
            >
                <div className="media pointer">
                    <span className="user-avatar bg-orange size-40 mr-3" style={{ color: "#fff" }}>
                        {contact.lastname ? contact.lastname.trim().substring(0, 1).toUpperCase() : null}
                        {contact.firstname ? contact.firstname.trim().substring(0, 1).toUpperCase() : null}
                    </span>

                    <div className="media-body">
                        {contact.address.street} {contact.address.housenumber}, {contact.address.zip}{" "}
                        {contact.address.city}
                        <div className="text-muted">
                            <small>
                                {contact.lastname && contact.firstname ? contact.lastname + ", " : contact.lastname}
                                {contact.firstname} {contact.label && <>({contact.label})</>}
                            </small>
                            {hint}
                        </div>
                    </div>
                </div>
            </ListEntry>
        );
    }

    renderContactList(contacts) {
        var page = this.state.currentPage;
        var conPerPage = this.state.contactsPerPage;
        var listOut = [];

        var maxContact = (page + 1) * conPerPage;

        if (maxContact > contacts.length) {
            maxContact = contacts.length;
        }

        for (var i = page * conPerPage; i < maxContact; i++) {
            listOut.push(this.renderContactListElement(contacts[i]));
        }

        return <List>{listOut}</List>;
    }

    updateMaxPages() {
        var contacts =
            this.state.currentTab === "open"
                ? this.state.contactsOpen
                : this.state.currentTab === "blocked"
                ? this.state.contactsBlocked
                : this.state.contactsClosed;

        var newMaxPages = Math.floor(contacts.length / this.state.contactsPerPage);

        this.setState({
            numPages: newMaxPages,
            currentPage: 0,
        });
    }

    changeTab = (key) => {
        this.setState({ currentTab: key }, () => this.updateMaxPages());
    };

    render() {
        if (this.state.isLoading) {
            return (
                <div style={{ marginTop: "30px", textAlign: "center", padding: "15px" }}>
                    <i className="zmdi zmdi-spinner zmdi-hc-fw rotating" style={{ fontSize: 28 }} />
                    <br />
                    Daten werden geladen ...
                </div>
            );
        }

        const openConacts = this.renderContactList(this.state.contactsOpen);
        const blockedContacts = this.renderContactList(this.state.contactsBlocked);
        const closedContacts = this.renderContactList(this.state.contactsClosed);

        return (
            <React.Fragment>
                <div style={{ paddingBottom: "18px" }}>
                    <div style={{ float: "right" }}>
                        <Link
                            to={"/campaign/" + this.props.match.params.campaignId + "/streets"}
                            className="btn btn-info"
                        >
                            Straßen
                        </Link>
                    </div>
                    <h1 className="title">{this.state.name}</h1>
                    <div>
                        {this.state.contactsClosed.length +
                            " / " +
                            (this.state.contactsClosed.length +
                                this.state.contactsOpen.length +
                                this.state.contactsBlocked.length) +
                            " Kontakte abgeschlossenen"}
                    </div>

                    <Tabs
                        defaultTab={this.state.currentTab}
                        changeTab={this.changeTab}
                        tabs={{
                            open: {
                                label: "Offen",
                                render: () => {
                                    if (!this.state.contactsOpen || this.state.contactsOpen.length < 1) {
                                        return (
                                            <div className="tabs-content">
                                                Keine offenen Kontakte in dieser Kampagne verfügbar.
                                            </div>
                                        );
                                    }
                                    //return (this.renderContactList(this.state.contactsOpen));
                                    return <React.Fragment>{openConacts}</React.Fragment>;
                                },
                            },
                            blocked: {
                                label: "Blockiert",
                                render: () => {
                                    if (!this.state.contactsBlocked || this.state.contactsBlocked.length < 1) {
                                        return (
                                            <div className="tabs-content">
                                                Keine blockierten Kontakte in dieser Kampagne verfügbar.
                                            </div>
                                        );
                                    }
                                    //return (this.renderContactList(this.state.contactsBlocked));
                                    return <React.Fragment>{blockedContacts}</React.Fragment>;
                                },
                            },
                            closed: {
                                label: "Abgeschlossen",
                                render: () => {
                                    if (!this.state.contactsClosed || this.state.contactsClosed.length < 1) {
                                        return (
                                            <div className="tabs-content">
                                                Keine abgeschlossenen Kontakte in dieser Kampagne verfügbar.
                                            </div>
                                        );
                                    }
                                    //return (this.renderContactList(this.state.contactsClosed));
                                    return <React.Fragment>{closedContacts}</React.Fragment>;
                                },
                            },
                        }}
                    />
                </div>
                <div className="container-fluid row" style={{ paddingBottom: "15px" }}>
                    <div className="col-6" style={{ marginTop: "15px" }}>
                        {this.state.currentPage > 0 ? (
                            <span
                                className={`jr-menu-icon pointer`}
                                style={{ float: "left", marginRight: "0px", whiteSpace: "nowrap", fontSize: "0.8rem" }}
                                onClick={() => this.setState({ currentPage: this.state.currentPage - 1 })}
                            >
                                <span
                                    className={"zmdi zmdi-long-arrow-left zmdi-hc-2x pointer"}
                                    style={{ paddingRight: "6px" }}
                                />
                                vorherhige Seite
                            </span>
                        ) : null}
                    </div>

                    <div className="col-6" style={{ marginTop: "15px" }}>
                        {this.state.currentPage < this.state.numPages ? (
                            <span
                                className={`jr-menu-icon pointer`}
                                style={{
                                    float: "right",
                                    marginRight: "36px",
                                    whiteSpace: "nowrap",
                                    fontSize: "0.8rem",
                                }}
                                onClick={() => this.setState({ currentPage: this.state.currentPage + 1 })}
                            >
                                nächste Seite
                                <span
                                    className={"zmdi zmdi-long-arrow-right zmdi-hc-2x pointer"}
                                    style={{ paddingLeft: "6px" }}
                                />
                            </span>
                        ) : null}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    backlink: state.app.backlink,
});
const mapDispatch = (dispatch) => ({
    setBacklink: (backlink) => dispatch(setBacklink(backlink)),
    setRelogin: (changeRelogin) => dispatch(setRelogin(changeRelogin)),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(View));
