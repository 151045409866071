import React from "react";

export class SideBar extends React.Component {
    render() {
        return (
            <div
                className={"app-sidebar d-xl-flex rc-drawer rc-drawer-right rc-drawer-open"}
                style={{ overflow: "fixed" }}
            >
                <div
                    className="rc-drawer-sidebar"
                    style={{
                        transform: this.props.visible ? "translateX(0%)" : "translateX(100%)",
                        WebkitTransform: this.props.visible ? "translateX(0%)" : "translateX(100%)",
                    }}
                >
                    <div className="side-nav">
                        <div className="sidebar-header d-flex align-items-center justify-content-between">
                            {/*<i className="zmdi zmdi-comment-alt-text icon-alert zmdi-hc-2x"></i>*/}
                            <i></i>
                            <i
                                className="zmdi zmdi-long-arrow-right zmdi-hc-2x pointer"
                                onClick={this.props.closeRequest}
                            ></i>
                        </div>

                        {this.props.children}
                    </div>
                </div>

                {this.props.visible ? (
                    <div
                        className="rc-drawer-overlay"
                        role="presentation"
                        style={{ opacity: this.props.visible ? 1 : 0, visibility: "visible" }}
                        onClick={this.props.closeRequest}
                    ></div>
                ) : null}
            </div>
        );
    }
}
