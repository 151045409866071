import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Header, HeaderLogo } from "../../theme/components/Header";
import HeaderToggleNav from "../../theme/components/Header/HeaderToggleNav";
import { SideBar, SideBarMenu } from "../SideBar";
import SideBarMenuItem from "../SideBar/SideBarMenuItem";
import { closeSearch, closeSidebar, openSearch, openSidebar, requestSearch, updateSearch } from "../../redux/actions";

class Layout extends Component {
    openNavigation() {
        this.closeSearch();

        this.props.openSidebar();
    }

    closeSidebar() {
        this.props.closeSidebar();
    }

    openSearch() {
        this.props.openSearch();
    }

    closeSearch() {
        this.props.closeSearch();

        this.props.updateSearch("");
    }

    handleSearchInput(event) {
        event.preventDefault();

        this.props.updateSearch(event.target.value);
    }

    submitSearch() {
        if (this.props.location.pathname !== "/search") {
            this.props.history.push("/search");
        }

        this.props.requestSearch(true);
    }

    render() {
        const sideBars = this.props.loginStatus ? (
            <React.Fragment>
                <SideBar visible={this.props.sidebarOpen} closeRequest={this.closeSidebar.bind(this)}>
                    <SideBarMenu Label="Hauptmenü">
                        <SideBarMenuItem Name="Dashboard" Path="/" />
                        <SideBarMenuItem Name="Logout" Path="/login" />
                    </SideBarMenu>
                </SideBar>

                {/*<SearchBar visible={this.props.searchOpen} closeRequest={this.closeSearch.bind(this)}>
                    <SearchBox
                        onChange={this.handleSearchInput.bind(this)}
                        submitSearch={this.submitSearch.bind(this)}
                        value={this.props.searchInput}
                        placeholder="Suchen"
                    />
                </SearchBar>*/}
            </React.Fragment>
        ) : null;

        const toggleNav =
            this.props.loginStatus && !this.props.relogin ? (
                <React.Fragment>
                    <HeaderToggleNav
                        openNavRequest={this.openNavigation.bind(this)}
                        openSearchRequest={this.openSearch.bind(this)}
                        history={this.props.history}
                    />
                </React.Fragment>
            ) : null;

        return (
            <div className="app-main">
                <div className={`app-container fixed-drawer`}>
                    {sideBars}

                    <div className="app-main-container">
                        <Header>
                            <HeaderLogo>
                                <img
                                    src="https://glasfaser.strompreisvergleichsrechner.com/bundles/app/images/logo.png"
                                    alt="logo"
                                    style={{
                                        height: "40px",
                                        marginTop: "15px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        this.props.history.push("/");
                                    }}
                                />
                            </HeaderLogo>
                            {toggleNav}
                        </Header>
                        <main className="app-main-content-wrapper">
                            <div className="app-main-content">
                                {/* SamplePage Component */}
                                <div className="app-wrapper">
                                    {this.props.relogin ? (
                                        <React.Fragment>
                                            <div style={{ marginTop: "30px" }} className="alert alert-danger">
                                                Das Token ist abgelaufen, bitte erneut anmelden.
                                                <br />
                                                <a href="/login">Direkt zum Login</a>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        this.props.children
                                    )}
                                </div>
                                {/* SamplePage Component ends  */}
                            </div>
                            {/* Footer Component */}
                            <footer className="app-footer">
                                <div className="d-flex flex-row justify-content-between">
                                    <div>
                                        <span>Powered by meeva GmbH</span>
                                    </div>
                                </div>
                            </footer>
                            {/* Footer Component ends */}
                        </main>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loginStatus: state.app.auth ? true : false,
    sidebarOpen: state.app.sidebarOpen,
    searchOpen: state.app.searchOpen,
    searchInput: state.app.searchInput,
    sendSearch: state.app.sendSearch,
    relogin: state.app.relogin,
});
const mapDispatch = (dispatch) => ({
    openSidebar: () => dispatch(openSidebar()),
    closeSidebar: () => dispatch(closeSidebar()),
    openSearch: () => dispatch(openSearch()),
    closeSearch: () => dispatch(closeSearch()),
    updateSearch: (searchInput) => dispatch(updateSearch(searchInput)),
    requestSearch: (sendSearch) => dispatch(requestSearch(sendSearch)),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(Layout));
