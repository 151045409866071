import React, { Component } from "react";
import { connect } from "react-redux";
import { setBacklink, setRelogin } from "../redux/actions";
import { withRouter } from "react-router-dom";
import { List, ListEntry } from "../theme";
import moment from "moment";
import ApiHelper from "../helpers/ApiHelper";

class Dashboard extends Component {
    state = {
        campaigns: null,
        isLoading: true,
    };

    componentDidMount() {
        this.props.setBacklink(null);

        this.setState({
            isLoading: true,
        });

        ApiHelper.request("/campaigns")
            .then((response) => {
                this.setState({ isLoading: false, campaigns: response.data });
            })
            .catch((e) => {
                if (e.statusText === "Forbidden") {
                    this.props.setRelogin(true);
                }
            });
    }

    renderCampaignListElement(campaign) {
        return (
            <ListEntry
                key={"campaign_" + campaign.id}
                onClick={() => this.props.history.push("/campaign/" + campaign.id + "/details")}
            >
                <div className="media">
                    <span className="user-avatar bg-blue size-40 mr-3" style={{ color: "#fff" }}>
                        {campaign.id}
                    </span>

                    <div className="media-body">
                        {campaign.name}
                        <div className="text-muted">
                            <small>
                                Beginn: {moment(campaign.begin).format("DD.MM.YYYY")}
                                <br />
                                Ende: {moment(campaign.end).format("DD.MM.YYYY")}
                            </small>
                        </div>
                    </div>
                </div>
            </ListEntry>
        );
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div style={{ marginTop: "30px", textAlign: "center", padding: "15px" }}>
                    <i className="zmdi zmdi-spinner zmdi-hc-fw rotating" style={{ fontSize: 28 }} />
                    <br />
                    Daten werden geladen ...
                </div>
            );
        }

        return (
            <div>
                <h1 className="title">Aktuelle Kampagnen</h1>
                <div className="jr-card p-0 border-0">
                    <div className="jr-card-body">
                        {this.state.campaigns && this.state.campaigns.length > 0 ? (
                            <List>
                                {this.state.campaigns.map((campaign) => this.renderCampaignListElement(campaign))}
                            </List>
                        ) : (
                            <div style={{ padding: "15px" }}>
                                <b>Keine Kampagnen verfügbar</b>
                                <br />
                                Dem eingeloggten Benutzeraccount wurden keine aktiven Kampagnen zugeordnet.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    backlink: state.app.backlink,
});
const mapDispatch = (dispatch) => ({
    setBacklink: (backlink) => dispatch(setBacklink(backlink)),
    setRelogin: (changeRelogin) => dispatch(setRelogin(changeRelogin)),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(Dashboard));
