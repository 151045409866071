import React from "react";
import { withRouter } from "react-router-dom";
import ApiHelper from "../../helpers/ApiHelper";

import { connect } from "react-redux";
import { setBacklink, setRelogin } from "../../redux/actions";

class Streets extends React.Component {
    state = {
        isLoading: true,
        id: null,
        name: null,
        streets: null,
    };

    componentDidMount() {
        this.props.setBacklink("/campaign/" + this.props.match.params.campaignId + "/details");
        this.load();
    }

    load() {
        this.setState({
            isLoading: true,
            contactsOpen: [],
            contactsBlocked: [],
            contactsClosed: [],
        });

        ApiHelper.request("/campaigns/streets", {
            id: this.props.match.params.campaignId,
        })
            .then((response) => {
                this.setState(
                    {
                        isLoading: false,
                        id: response.data.id,
                        name: response.data.name,
                        streets: response.data.streets,
                    },
                    () => {
                        // this.updateMaxPages();
                    }
                );
            })
            .catch((e) => {
                console.error(e);
                this.setState({ isLoading: false, contactsOpen: [], contactsClosed: [] });

                if (e.statusText === "Forbidden") {
                    this.props.setRelogin(true);
                }
            });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div style={{ marginTop: "30px", textAlign: "center", padding: "15px" }}>
                    <i className="zmdi zmdi-spinner zmdi-hc-fw rotating" style={{ fontSize: 28 }} />
                    <br />
                    Daten werden geladen ...
                </div>
            );
        }

        let streetsCount = 0;
        let lastCity = null;
        return (
            <React.Fragment>
                <div style={{ paddingBottom: "18px" }}>
                    <h1 className="title">{this.state.name}</h1>
                    <h2>Auswertung nach Straßen</h2>

                    {this.state.streets && this.state.streets.length > 0 ? (
                        <div className="card">
                            <table className="table mb-0">
                                <tbody>
                                    {this.state.streets.map((street) => {
                                        let streetAddition;
                                        if (lastCity !== street.city) {
                                            streetAddition = [
                                                <tr key={"city_" + street.city + "_" + streetsCount}>
                                                    <td
                                                        colSpan="2"
                                                        style={{ fontWeight: "bold", padding: "10px 0.75rem" }}
                                                    >
                                                        {street.city}
                                                    </td>
                                                </tr>,
                                                <tr>
                                                    <td style={{ fontSize: "12px", padding: "3px 0.75rem" }}>Straße</td>
                                                    <td
                                                        style={{ fontSize: "12px", padding: "3px 0.75rem" }}
                                                        className="text-right"
                                                    >
                                                        Kontakte
                                                    </td>
                                                </tr>,
                                            ];
                                        }

                                        lastCity = street.city;

                                        streetsCount++;
                                        return [
                                            streetAddition,
                                            <tr key={"street_" + streetsCount}>
                                                <td>{street.street}</td>
                                                <td className="text-right">{street.contacts}</td>
                                            </tr>,
                                        ];
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="alert alert-danger">Keine Straßen in der Kampagne vorhanden ...</div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    backlink: state.app.backlink,
});
const mapDispatch = (dispatch) => ({
    setBacklink: (backlink) => dispatch(setBacklink(backlink)),
    setRelogin: (changeRelogin) => dispatch(setRelogin(changeRelogin)),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(Streets));
