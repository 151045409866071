import React from 'react';

const CardLayout = ({children}) => {
    return (
        <div className={`jr-card`}>
            {children}
        </div>
    )
};

export default CardLayout;

